import React from 'react';


export const Widget = () => (
  <div>
    <iframe
      title="widget"
      id='iframe-widget'
      src='https://changenow.io/embeds/exchange-widget/v2/widget.html?amount=0.1&from=btc&link_id=e4a4fd613b183d&to=eth&FAQ=true&logo=false'
      width='100%'
      height='350px'
      frameBorder='0'/>
    <script async type='text/javascript' src='https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js'/>
  </div>
);
