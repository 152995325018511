import React, { useState } from 'react';
import './styles.css';

import { tosContent, privacyPolicyContent } from '../../assets/content'

export const PrivacyPolicy = () => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showToS, setShowToS] = useState(false);

    return (
        <div>
            <div className="document-container">
                <button onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}>
                    Privacy Policy
                </button>
                <div className={`document-content ${showPrivacyPolicy ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: privacyPolicyContent }} />
            </div>
            <div className="document-container">
                <button onClick={() => setShowToS(!showToS)}>
                    Terms of Service
                </button>
                <div className={`document-content ${!showToS ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: tosContent }} />
            </div>
        </div>
    );
}

