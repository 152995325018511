export const JUMBO_TITLE = 'Coin Crypto';
export const JUMBO_TEXT_1 = 'Crypto connects the world. It’s fast, private, and secure.';

export const jumbo = {
  TEXT_1: 'Coin Crypto provides the fastest way to swap more than 200 currencies',
}

export const INFORMATION_TITLE = 'How Exchange Works';

export const works = [
  {
    NUMBER: 1,
    TITLE: 'CHOOSE',
    // eslint-disable-next-line no-multi-str
    DESCRIPTION: 'Select the desired exchange pair.',
  },
  {
    NUMBER: 2,
    TITLE: 'CONFIRM',
    DESCRIPTION: 'Enter your receiving wallet address, check the entered values and confirm the exchange.',
  },
  {
    NUMBER: 3,
    TITLE: 'RECEIVE',
    DESCRIPTION: 'After processing the transaction, the cryptocurrency will be sent to your wallet in a few minutes.\n',
  }
];

export const FAQ_TITLE_DESKTOP = 'Exchange Q&A';

export const listAnswer = [
  {
    QUESTIONS: 'How long does it take?',
    ANSWER: 'The processing speed ranges from two to twenty minutes. If the transaction is large, processing it may take a bit longer, depending on the size of the transaction and capacity of the block.'
  },
  {
    QUESTIONS: 'What are your fees?',
    ANSWER: 'The fees vary depending on the currency and exchange amount, for every transaction our provider finds the fastest and most user-profitable rate on the market at the moment of exchange. In any case, what you see is what you get!'
  },
  {
    QUESTIONS: 'Are there any limits?',
    ANSWER: 'There are no upper limits on the exchange of any cryptocurrency. But depending on the coin, the lower limits vary from ~$1.7 to $20.'
  },
];

export const privacyPolicyContent = '<div style="page-break-before:always; page-break-after:always"><div><p><b>Privacy Policy</b></p>\n' +
    '<p>Last Updated: NOVEMBER 22, 2023</p>\n' +
    '<p>This policy outlines how we collect, use, and protect your personal information when you use our Services. Please read it carefully to understand our practices regarding your data.</p>\n' +
    '<p>1. What Information We Collect Depending on the service you wish to use, we may collect the following types of information: </p>\n' +
    '<p>&#9679; Email<br/>&#9679; Transaction logs<br/>&#9679; Data collected via cookies and similar technologies </p>\n' +
    '<p>We may also gather information about your device, such as your IP address, operating system, and browser type, for system administration, problem-solving, and service improvement. This data is purely statistical and does not identify any individual. Our website uses cookies to analyze visits and enhance your experience. You have the right to refuse cookies or limit their use, in which case we will only use essential cookies for website operation. You can also delete cookies from your browser settings. </p>\n' +
    '<p>2. Why We Collect Your Personal Information<br/><br/>We collect the minimum necessary personal data to support your privacy rights. By accepting this Privacy Policy, you allow us to use your email address to contact you and suggest further cooperation. You can opt-out of receiving such emails at any time. We may also use email for user support. We process technical information and cookies to improve our services for statistical and analytical purposes. We do not use automated processing of personal data for decisions with legal consequences. We guarantee that we do not sell users\' personal data for monetary gain, and we do not provide personal information to third parties for marketing purposes. </p>\n' +
    '<p>3. Why We Are Entitled to Process Your Personal Information<br/><br/>We rely on the following grounds for data processing: </p>\n' +
    '<p>&#9679; Your explicit informed consent with this Policy.<br/>&#9679; Our legitimate interest. </p>\n' +
    '<p>We obtain your consent for processing personal data in accordance with this Policy. You can withdraw your consent or request data erasure at any time. </p>\n' +
    '<p>4. Sharing Personal Information with Other Parties</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>We may share your information with third-party providers under contract for services such as cryptocurrency exchange and purchases. We ensure that these parties are aware of our obligations under this Privacy Policy and prohibit them from selling your information. Financial institutions with which we partner to process payments you authorize may also receive your information. We may share your information with law enforcement or regulatory agencies as required by law. </p>\n' +
    '<p>5. Protection of Personal Information <br/><br/> We take data security seriously. We employ encryption for all network connections, regularly test systems for vulnerabilities, protect access to confidential data with passwords, and monitor logs and security events. Our employees and third parties accessing your data are bound by confidentiality obligations. While we implement security measures, we cannot guarantee absolute protection against unforeseen or illegal activities. In the event of a data breach, we will promptly notify users and the regulatory authority and minimize negative consequences. </p>\n' +
    '<p>6. Data Retention<br/><br/> We retain your information only as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, resolve disputes, and enforce agreements. Upon request, we will delete your personal data within two weeks, in accordance with applicable laws and regulations. Some data may be retained for legal, regulatory, or technical purposes. This Privacy Policy is subject to change, and we may update it to comply with relevant laws and regulations. Any changes take effect upon publication on this page. While we will endeavor to notify users of policy changes, we encourage you to check for updates regularly. </p>\n' +
    '<p>7. About Us Legal name: FD Transfers LLC Legal address: Suite 305, Griffith Corporate Centre, Beachmont, Kingstown </p>\n' +
    '<p>8. Amendment of the Policy <br/>This Policy is presented in the most up-to-date version. Over time, FD Transfers LLC may unilaterally amend the Policy, including to comply with the latest changes in applicable law and jurisprudence. All changes come into force upon publication on this page, unless a different period is indicated in the text of the amendments. We will make every effort to organize additional ways to notify users of amendments to the Policy, but we ask you to regularly check for the latest version on the website yourself.</p>';



export const tosContent = '<div style="page-break-before:always; page-break-after:always"><div><p><b>TERMS OF SERVICENOVEMBER 22, 2023</b></p>\n' +
    '<p>These Terms of Service (&quot;Terms&quot;) constitute a legal agreement between You (&quot;User&quot; or&quot;You&quot;) and FD Transfers LLC, company registered under the laws of St. Vincent and theGrenadines and having its registered address at Suite 305, Griffith Corporate Centre,Beachmont, Kingstown (&quot;Company&quot; or &quot;we&quot;) governing your use of the Services provided byCompany (the &quot;Services&quot;). By accessing or using the Services, You agree to be bound by these Terms.</p>\n' +
    '<p>IMPORTANT NOTICE: PLEASE CAREFULLY REVIEW THESE TERMS OF SERVICE. BY USING OURSERVICES, YOU ACKNOWLEDGE YOUR LEGAL BOUNDING TO THESE TERMS. IF YOU DISAGREEWITH ANY PORTION OF THIS AGREEMENT, PLEASE REFRAIN FROM USING OUR SERVICES. IFANY ASPECT OF THIS DOCUMENT IS UNCLEAR, FEEL FREE TO CONTACT OUR SUPPORT TEAMAT SUPPORT@COINCRYPTO.TO.PLEASE BE AWARE THAT ENGAGING WITH VIRTUAL ASSETS ENTAILS SIGNIFICANT RISKS. THEVALUE OF VIRTUAL ASSETS CAN FLUCTUATE, AND THERE IS A CONSIDERABLE RISK OF VALUELOSS IN TRANSACTIONS INVOLVING THEM. TAKE NOTE THAT VIRTUAL ASSETS AND CRYPTOSERVICES PROVIDED BY COMPANIES ARE CURRENTLY NOT GOVERNED BY THE AUTHORITY OFST. VINCENT AND THE GRENADINES. BEFORE PARTICIPATING IN ANY ACTIVITY INVOLVINGVIRTUAL ASSETS, CAREFULLY ASSESS YOUR FINANCIAL SITUATION AND WHETHER SUCHENGAGEMENT SUITS YOU.</p>\n' +
    '<p><b>Table of Contents</b></p>\n' +
    '<p>1. GENERAL CONDITIONS 32. DEFINITIONS 43. STAKING SERVICES PROVISION 54. STAKING REWARDS RATE 65. USER RESPONSIBILITIES 76. RESTRICTIONS ON USE OF THE SERVICES 77. PROHIBITED JURISDICTIONS 88. INTELLECTUAL PROPRIETARY RIGHTS 99. THIRD PARTY SERVICES. 910. TERM AND TERMINATION 1011. INDEMNIFICATION 1012. EXCLUSION OF WARRANTIES 1113. LIMITATION OF LIABILITY 11</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>14. GOVERNING LAW AND DISPUTE RESOLUTION 1215. PERSONAL DATA 1216. TAXES 1217. MISCELLANEOUS 13</p>\n' +
    '<p><b>1. GENERAL PROVISIONS</b></p>\n' +
    '<p>1.1. Terms govern the use of the Website https://coincrypto.to/ (the &#8220;Website&#8221;) and allother related Websites and Services available on or through any of the foregoing(collectively, the &#8220;Services&#8221;). These Terms do not govern your access and use of anysoftware, Websites and Services which may be made available to You under separatelicence terms from the third party service provider.</p>\n' +
    '<p>1.2. The current effective version of the Terms is located at the Website:<b>https://coincrypto.to/</b>. Company may make changes to the Terms from time to time.When such changes are made, Company will make the updated Terms available on orthrough the Website. Please, check these Terms periodically for updates. Yourcontinued use of the Services after such changes have been published on or throughthe Website shall constitute your binding acceptance of such changes.</p>\n' +
    '<p>1.3. Use of the Services constitutes an acceptance and confirmation of your consent to bebound by these Terms, all applicable laws and regulations, and You agree that You areresponsible for compliance with and that You are compliant with these Terms and theapplicable laws. If You do not agree with all these Terms, then You shall not access oruse any of our Services.</p>\n' +
    '<p>1.4. Please refer to our Privacy Policy https://coincrypto.to/ for information on how wecollect, use and disclose information from our Users. You acknowledge and agreethat your use of Services is subject to, and that we can collect, use and/or discloseyour information (including any personal data You provide to us) in accordance withour Privacy Policy.</p>\n' +
    '<p>1.5. In order to be able to use our Services You warrant and represent that You:1.5.1. are at least 18 years old or of other legal age, according to the relevant jurisdiction [OR] a registered business entity and comply with all applicable laws andregulations to use the Services;</p>\n' +
    '<p>1.5.2. You are only transacting on the Service with legally obtained funds thatbelong to You;</p>\n' +
    '<p>1.5.3. You are not furthering, performing, undertaking, engaging in, aiding orabetting any unlawful activity through your relationship with us or throughyour use of the Services;</p>\n' +
    '<p>1.5.4. You are complying with these Terms;</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>1.5.5. You acknowledge and agree that Company is not acting as your broker,intermediary, agent or advisor or in any fiduciary capacity, and nocommunication or information provided to You by Company shall beconsidered or construed as advice.</p>\n' +
    '<p><b>2. DEFINITIONS</b></p>\n' +
    '<p>2.1. Dispute: any disagreement, claim, or dispute arising between the User and Companyregarding the use of the Services or any related matters.</p>\n' +
    '<p>2.2. User (also known as You): pertains to any individual or entity engaging with ourservices as described in these Terms of Use.</p>\n' +
    '<p>2.3. Services: the range of offerings detailed in sections 3-5 of these Terms of Use,encompassing cryptocurrency-related services and functionalities provided byCompany.</p>\n' +
    '<p>2.4. Third Party (also known as Partner): any individual, entity, or service provider that isnot directly affiliated with Company but may be involved in the provision orfacilitation of certain Services.</p>\n' +
    '<p>2.5. Third Party Services: Services provided by Third Party entities that may be integratedor used in conjunction with Company\'s Services, subject to their respective terms andconditions.</p>\n' +
    '<p>2.6. Website: The online platform (https://coincrypto.to/) operated by Company throughwhich the Services are made available to User.</p>\n' +
    '<p>2.7. Virtual Asset: a digital representation of a value or a right which may be transferredand stored electronically, using distributed ledger technology or similar technology.</p>\n' +
    '<p>2.8. Accepted Virtual Asset: refers to a cryptocurrency or digital token that has beenofficially listed and approved by the Company for Staking, subject to the terms andconditions outlined in these Terms of Use.</p>\n' +
    '<p>2.9. Staking: a process of securely holding and dedicating a specific quantity of asupported cryptocurrency or digital token (also known as &#8220;Accepted Virtual Asset&#8221;)within a designated network\'s wallet. This commitment aids in supporting thenetwork\'s operational activities, enhancing its security, and facilitating its validationprocesses.</p>\n' +
    '<p>2.10. Validator: a participant within a Proof of Stake (PoS) blockchain network, entrustedwith the duty of authenticating fresh transactions and safeguarding the blockchain\'ssecurity. This validation process is achieved through the act of staking theircryptocurrency to bolster the network\'s functionality.</p>\n' +
    '<p>2.11. Staking reward rate: the specified annualized historical rate, detailed in section 4.1 ofthese Terms of Use, at which staking rewards are generated by the Company\'s stakingservices for a particular Accepted Virtual Asset, minus any applicable commission.</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p><b>3. STAKING SERVICES PROVISION</b></p>\n' +
    '<p>3.1. PLEASE NOTE! Virtual Assets&#8217; Staking carries inherent risks due to its complex natureand market volatility. Fluctuations in token value, network vulnerabilities, andpotential technical failures can lead to significant financial losses. Furthermore,staking may expose users to potential security breaches and hacking risks. <b>We do notguarantee the payment of interests or that the chosen currency will not besubjected to scams. </b>It\'s crucial to understand these dangers before engaging instaking activities.</p>\n' +
    '<p>3.2. Kindly be aware that each Accepted Virtual Asset operates under its individual policyand rules. Users are advised to acquaint themselves with these rules before engagingin Staking activities. The transaction percentage is contingent upon the network.</p>\n' +
    '<p>3.3. If You possess Accepted Virtual Asset, You might have the opportunity to &quot;stake&quot;these Assets in Validator provided by Third Party&#8217;s proof of stake network throughstaking Services provided by Company or an affiliated entity, which could include aThird Party Validator operator. In a proof of stake network, transaction Validators areselected based on the quantity of underlying Accepted Virtual Asset staked by theValidator, rather than computational power (i.e., proof of work). When You engage inthese staking Services, You agree to abide by the terms set forth in this Terms.3.3.1. To utilize our Services through the third party&#8217;s application, select the desired Virtual Asset for Staking and proceed by clicking &quot;Stake.&quot; <b>Be awarethat the minimum deposit required for staking might vary. </b>Then, verifythe designated staking amount and commence earning rewards.</p>\n' +
    '<p>3.4. The eligibility of your Accepted Virtual Asset to participate in the transactionvalidation process and earn rewards may experience delays based on the protocol. IfCompany or one of its affiliates successfully accomplishes a validation task within thenetwork of a Accepted Virtual Asset, You may be rewarded by that network as per itsprotocols. Rewards are determined by the network\'s protocols. If the networkdistributes rewards in an unstaked manner, Company will make reasonable efforts torestake these rewards. You retain the right to request the unstaking of youraccumulated rewards at any point, as elaborated in these Terms. Rewards will becredited to your account, factoring in the staked amount of your principal andpreviously accrued rewards with Company. After Company receives the rewards, theywill be credited to your account, minus a commission. The current commission foreach. Company reserves the right to modify these published commissions at anytime, even after your Accepted Virtual Asset have been staked.</p>\n' +
    '<p>3.5. Some Virtual Asset networks apply a &quot;slashing&quot; penalty to staked Virtual Assets whenthe transaction Validator, representing those assets, incorrectly validates atransaction. The Company will take reasonable steps to prevent any slashing ofstaked Accepted Virtual Assets. If slashing occurs, the Company will not replace yourstaked Accepted Virtual Asset, including such penalties arise from:<br/>3.5.1. protocol-level issues arising from bugs, maintenance, upgrades, or general failures; <br/>3.5.2. actions or oversights on your part;<br/>3.5.3. actions or oversights of any Third Party service provider;<br/>3.5.4. events meeting the criteria outlined in Section 8.7 of these Terms;<br/>3.5.5. actions undertaken by hackers or other malicious actors;<br/>3.5.6. any other occurrences beyond Company\'s reasonable control.</p>\n' +
    '<p>3.6. Certain Virtual Asset networks mandate the locking of a specific amount of stakedVirtual Assets for a designated duration, during which they cannot be sold ortransferred. Prior to selling or transferring your staked Accepted Virtual Asset, Youmust request to have them unstaked. Upon your unstaking request, Company willinitiate blockchain operations to conclude your Accepted Virtual Asset\'s involvementin the validation process of the relevant protocol. These operations could take up to48 hours to finalize, alongside any relevant protocol unstaking timeframe. Youreligibility for staking rewards during the unstaking process varies according to theprotocol. Projected unstaking periods serve as approximations only. We will informYou upon completion of the unstaking process.</p>\n' +
    '<p>3.7. You retain ownership of Virtual Assets, and this ownership is not transferred toCompany. Any interests in Virtual Assets held in Third Party accounts are held onbehalf of customers and are not Company&#8217;s property. As the Virtual Assets owner,you assume all risks related to potential loss of such assets. None of the VirtualAssets in your Account are Company&#8217;s property, and Company does not consider ortreat them as such.</p>\n' +
    '<p><b>4. ETHEREUM STAKING AND WRAPPING</b></p>\n' +
    '<p>4.1. Kindly be aware that each Accepted Virtual Asset operates under its individual policyand rules. Users are advised to acquaint themselves with these rules before engagingin Staking activities. The transaction percentage is contingent upon the network.</p>\n' +
    '<p>4.2. In some legal jurisdictions, you have the option to enable the capability to utilize yourstaked ETH for selling, sending, spending, or other forms of utilization by electing towrap it into a token that represents both the staked ETH and its associated rewards.This service\'s availability may be limited, and additional eligibility criteria could beapplicable. If you choose to wrap your staked ETH and associated rewards into theCompany\'s ETH, referred to as &quot;GETH,&quot; you acknowledge and agree with thefollowing:</p>\n' +
    '<p>4.3. Once the wrapping process is completed, you will be able to redeem your stakedETH or access any associated rewards, except as detailed in Clause 3-5 of theseTerms;</p>\n' +
    '<p>4.4. GETH held in your Virtual Asset wallet symbolizes ownership of ETH staked with theCompany, following the terms set out in these Terms (including associated rewards,minus any Company fees and slashing penalties). By wrapping staked ETH as GETH,you retain all the economic value, risks, and rewards associated with the staked ETH.</p>\n' +
    '<p>4.5. The sale or transfer of GETH automatically transfers ownership of the staked ETH andthe redemption rights outlined in these Terms. This transfer entitles the recipient tothe related economic value, risks, and rewards tied to the staked ETH, as per theprovisions of these Terms. It\'s important to clarify that receiving GETH doesn\'t, on its own, establish a contractual relationship with the Company. In all cases, the right toredeem GETH is subject to the terms delineated in these Terms.</p>\n' +
    '<p>4.6. The Company holds staked ETH and associated rewards, wrapped as GETH, on behalfof GETH holders, and ownership of these assets remains with the holders, not theCompany.</p>\n' +
    '<p>4.7. GETH is an ERC-20 token and may be compatible with protocols, software, ortechnology provided by Third Parties.</p>\n' +
    '<p>4.8. User agrees that Company has the authority to engage Third Party Service provider indistribution and sending staking rewards rate for the said Accepted Virtual Asset.However, Company DOES NOT remain responsible to the User for the actions andconduct of these third-party providers.</p>\n' +
    '<p>4.9. The staked ETH and rewards held by the Company on behalf of GETH holders aresubject to the risk of slashing as described in Section 3.5 of these Terms.Furthermore, you acknowledge, agree to, and assume the following risks associatedwith choosing to wrap into GETH: <br/>4.9.1. Neither Company nor its affiliates provide any guarantee regarding the value of your staked ETH principal or associated rewards.<br/>4.9.2. Neither Company nor its affiliates hold responsibility for any reduction in the value of your staked ETH principal or associated rewards.<br/>4.9.3. The price of GETH might diverge from the price of ETH or staked ETH due to market fluctuations. These fluctuations may be influenced by the actionsor inactions of market makers or other market participants receiving loansor incentives to acquire GETH.<br/>4.9.4. The Company and its affiliates do not provide a guarantee that wrappingstaked ETH will lead to a successful exchange or sale of GETH in due time<br/>4.9.5. The Company and its affiliates do not guarantee the security orfunctionality of any Third Party protocol, software, or technology intendedto be compatible with GETH. Neither the Company nor its affiliates areaccountable for any losses of GETH due to the failure of Third Partyprotocol, software, or technology.<br/>4.9.6. Similar to the protocols for other Virtual Assets, the Company and itsaffiliates do not own or control the underlying Ethereum protocolgoverning the operation of GETH.<br/>4.9.7. Just like other Virtual Assets, GETH could be impacted by regulatoryactions, which may restrict or limit the services we can provide concerningGETH.</p>\n' +
    '<p><b>5. STAKING REWARDS RATE</b></p>\n' +
    '<p>5.1. If Staking Reward Rate is not transferred by the network automatically, You do notpossess entitlement to a reward until Company has received it. Please note thatrewards may not be promptly credited to your account once they have been receivedby Company. Unless expressly specified, the &quot;staking rewards rate&quot; presented by Company for a specific Accepted Virtual Asset constitutes an annualised historicalrate derived from staking rewards generated by Company while offering stakingServices to its customers for the said Accepted Virtual Asset, after deducting ourcommission. This rate serves as an approximation and is subject to change over time.<b>Company cannot guarantee the receipt of staking rewards, any specific stakingreward, or any return from staking over time, including the staking rewards ratesand boosted staking rewards.</b></p>\n' +
    '<p>5.2. User agrees that Company has the authority to engage Third Party Service provider indistribution send staking rewards rate for the said Accepted Virtual Asset. However,Company DOES NOT remain responsible to the User for the actions and conduct ofthese third-party providers.</p>\n' +
    '<p>5.3. Company shall not be held liable in the event of the Validator\'s failure to generaterewards. Company&#8217;s reward distribution is contingent upon the received amount.This may occasionally be influenced by the characteristics of the blockchain network.Should rewards not have been accumulated, Company shall abstain from theirdistribution.</p>\n' +
    '<p><b>6. USER RESPONSIBILITIES</b></p>\n' +
    '<p>6.1. By accessing or using the Services, You represent and warrant that You are eligible todo so in accordance with Section 1.6 and 5 of these Terms of Service and yourcurrent legislation.</p>\n' +
    '<p>6.2. You agree to comply with all applicable laws and regulations related to Virtual Assets,including but not limited to anti-money laundering (AML) and know your customer orknow your business (KYC/KYB) requirements.</p>\n' +
    '<p>6.3. You are responsible for following appropriate security measures to protect youraccess to the Services, including but not limited to safeguarding login credentials. Asa User, You are prohibited from sharing your account access with anyone.</p>\n' +
    '<p>6.4. You are responsible for providing accurate and valid instructions regarding themanagement and transfer of your Virtual Assets stored within the Services.</p>\n' +
    '<p><b>7. RESTRICTIONS ON USE OF THE SERVICES</b></p>\n' +
    '<p>7.1. You agree that You are responsible for your own conduct while accessing or usingServices and for any consequences thereof. You agree to use Services only forpurposes that are legal, proper and in accordance with these Terms and anyapplicable laws or regulations. By way of example, and not as a limitation, You maynot allow any third party to:<p>7.1.1. send, upload, distribute or disseminate or offer to do the same with respect to any unlawful, defamatory, harassing, abusive, fraudulent,obscene, or otherwise objectionable content;</p>\n' +
    '<p>7.1.2. distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes,or any other items of a destructive or deceptive nature;</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>7.1.3. upload, post, transmit or otherwise make available through Service anycontent that infringes any patent, trademark, copyright, trade secret orother proprietary right of any party, unless You are the owner of suchrights or have the permission of the owner to post such content;</p>\n' +
    '<p>7.1.4. use Services to violate the legal rights (such as rights of privacy andpublicity) of others, including your customers, or send through Services anycontent, which is unauthorized or unsolicited advertising, junk or bulke-mail;</p>\n' +
    '<p>7.1.5. carry on any unlawful businesses and activities such as, for example: (i)creation, sale or distribution of sites offering Services such as prostitution,escorts; (ii) fraudulent business, sale of counterfeit or stolen items or thesale of goods or service that are illegally imported or exported; (iii) sale ofnarcotics, illegal substances or controlled substances, any equipment formaking or using such drugs; (iv) pyramid schemes, high risk investmentsschemes and other business that we determine in our sole discretion to beunfair, deceptive or predatory towards consumers; (v) any business that webelieve poses elevated financial risk, illegal liability, or violates the law ofsender or recipient of payment;</p>\n' +
    '<p>7.1.6. modify, adapt or reverse engineer any portion of Website or any softwareused for providing Services;</p>\n' +
    '<p>7.1.7. remove any copyright, trademark or other proprietary rights noticescontained in or on Website or any content posted thereon;</p>\n' +
    '<p>7.1.8. display any content through Services that contains any hate-related orviolent information or contains any other material, products or Servicesthat violate or encourage conduct that would violate any criminal laws, anyother applicable laws, or any third party rights;</p>\n' +
    '<p>7.1.9. use any robot, spider, site search/retrieval application, or other device tocollect information about its customers for any unauthorized purposes;</p>\n' +
    '<p>7.1.10. access or use Services for the purpose of bringing an intellectual propertyinfringement claim against Company or for the purpose of interfering withthe proper functioning of Services or creating a product or servicecompetitive with the Services;</p>\n' +
    '<p>7.1.11. use Services in ways not specified in these Terms.7.2. If User violates these Terms Company has the right (though not the obligation) to, in</p>\n' +
    '<p>Company&#8217;s sole discretion (without User&#8217;s consent or notice), block, terminate ordeny access to and use of Services. Company shall not give reasons for its actions.</p>\n' +
    '<p><b>8. PROHIBITED JURISDICTIONS</b></p>\n' +
    '<p>8.1. By accessing and using Company&#8217;s Services and the Website, You represent andwarrant that You are not located in or are not a citizen or resident of the UnitedStates of America, or a country subject to United Nations Sanctions Lists and theirequivalent, or a country where usage of cryptocurrency is forbidden by applicablelaw.</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>8.2. Before using our Services, please ensure that cryptocurrency usage is not prohibitedin your country. It is your responsibility to check your eligibility for using our service.</p>\n' +
    '<p>8.3. Some Third Party Service providers may have country-specific restrictions. Refer toour Third Party Service provider Terms of Use or Website for the list of availablecountries.</p>\n' +
    '<p>8.4. Use of the Services is void where prohibited by the applicable law and the laws of therelevant jurisdictions.</p>\n' +
    '<p>8.5. Company maintains the right to select its markets and jurisdictions to operate in andmay restrict or deny its Services to certain countries.</p>\n' +
    '<p>8.6. Company also maintains the right to use various methods to prevent the use of theServices by the Users listed above. You shall comply with these Terms, even ifCompany&#8217;s methods to prevent use of the Services are not effective or can bebypassed.</p>\n' +
    '<p><b>9. INTELLECTUAL PROPRIETARY RIGHTS</b></p>\n' +
    '<p>9.1. You acknowledge and agree that Company owns all legal rights, title and interest inand to the Website and any software used for rendering Services. The visualinterfaces, graphics, design, systems, methods, information, computer code,software, Services, organisation, compilation of the content, code, data, and all otherelements of the Services are protected by international copyright laws, andtrademark laws, international treaties, and all other relevant intellectual propertyand proprietary rights, and other applicable laws.</p>\n' +
    '<p>9.2. Furthermore, all trademarks, and trade names contained in the Services areproprietary to Company and may not be used without permission in connection withany Third Party products or Services. You will not remove, alter or obscure anycopyright, trademark, service mark or other proprietary rights notices incorporated inor accompanying the Website or the Services.</p>\n' +
    '<p>9.3. Except as expressly set forth herein, your use of the Services does not grant to yourownership of or any other rights with respect to any content, code, data, software orother materials that You may access on or through the Services. Company reserves allrights to the Services not expressly granted herein.</p>\n' +
    '<p><b>10. THIRD PARTY SERVICES.</b></p>\n' +
    '<p>10.1. The Services may incorporate, or may provide access to, applications, Services,products, or software developed or hosted by a Third Party. You agree that it isimpossible for Company to monitor Third Party Services and that You access them atyour own risk.</p>\n' +
    '<p>10.2. If You use any Third Party Services available on Our Website, You understand that:<p>10.2.1. Third Party Services are not controlled by Company, and Company shall not be responsible or liable to anyone for Third Party Services;</p><p>10.2.2. Your use of Third Party Services is solely between You and the respective and Third Party will be governed by the Third Party&#8217;s terms and policies. It is your responsibility to review the Third Party&#8217;s terms and policies beforeusing a Third Party Services;</p>\n' +
    '<p>10.2.3. Some Third Party Services may request or require access to your (yourcustomers&#8217;) data. If You grant access, your data will be handled inaccordance with the Third Party&#8217;s privacy policy and practices. Companydoes not have control over how a Third Party Services may use your oryour customers&#8217; data. Do not share any credential, private key, or othersensitive information with any Third Party without validating theirlegitimacy;</p>\n' +
    '<p>10.2.4. Third Party Services may not work appropriately with your software, andCompany may not be able to provide support for issues caused by anyThird Party Services. If You have questions or concerns about how a ThirdParty Service operates, or need support, please contact the Third Partydirectly.</p>\n' +
    '<p>10.3. In some cases, Company may at its discretion suspend, disable, or remove Third PartyServices.</p>\n' +
    '<p>10.4. Company disclaims any responsibility for any harm resulting from your use ofWebsites, webpages, software, Services and content of the Third Party.</p>\n' +
    '<p><b>11. TERM AND TERMINATION</b></p>\n' +
    '<p>11.1. These Terms shall enter into force for the User since the first use of the Services andshall be in force for an indefinite period.</p>\n' +
    '<p>11.2. You agree that Company, in its sole discretion and for any or no reason, may at anytime terminate these Terms and your account for the Services with immediate effect.You agree that any termination of your access to the Services may be without priornotice, and You agree that Company will not be liable to You or any third party forsuch termination. Company shall not give reasons for its actions. Company will haveno obligation to provide a refund of any amounts previously paid by You.</p>\n' +
    '<p>11.3. Upon termination of the Services, your right to use such Services will automaticallyterminate immediately. Company will not have any liability whatsoever to You for anysuspension or termination.</p>\n' +
    '<p>11.4. All provisions of the Terms, which by their nature should survive termination ofServices, will do so.</p>\n' +
    '<p><b>12. INDEMNIFICATION</b></p>\n' +
    '<p>12.1. You agree to hold harmless and indemnify Company, its parents, subsidiaries,affiliates, officers, employees, agents, partners and licensors, from and against anyThird Party claim arising from or in any way related to:</p><p>12.1.1. Your use of the Services;</p><p>12.1.2. your breach of these Terms;</p><p>12.1.3. your violation of applicable laws, rules or regulations in connection withthe Services;</p>\n' +
    '<p>12.1.4. your goods, products and Services, including any liability or expense arisingfrom any claims, losses, damages (actual and consequential), suits,judgments, litigation costs of every kind and nature.</p>\n' +
    '<p>12.2. In case of third party claim, Company will provide You:</p><p>12.2.1. with written notice of such claim, suit or action;</p><p>12.2.2. the opportunity to control the defence and/or settlement of such claim, suit or action;</p><p>12.2.3. reasonable assistance in such defence or settlement, upon reasonable request and at your expense.</p>\n' +
    '<p><b>13. EXCLUSION OF WARRANTIES</b></p>\n' +
    '<p>13.1. Nothing in these terms shall exclude or limit Company\'s warranties or liabilities forlosses which may not be lawfully excluded or limited by applicable law.</p>\n' +
    '<p>13.2. You expressly understand and agree that your use of the Services is at your sole riskand that the Services are provided &quot;as is&quot; and &quot;as available&quot; without warranties ofany kind either express or implied. To the fullest extent permissible pursuant toapplicable law, Company makes no express warranties and disclaims all impliedwarranties regarding the Services, including implied warranties of fitness for aparticular purpose, non-infringement, correctness, accuracy, and reliability.</p>\n' +
    '<p>13.3. Without limiting the generality of the foregoing, Company does not represent orwarrant to You that:</p><p>13.3.1. Your use of the Services will meet your requirements.</p><p>13.3.2. Your use of the Services will be uninterrupted, timely, secure, or free from error.</p><p>13.3.3. Usage data provided through the Services will be accurate.</p><p>13.3.4. The Services or any content, Services, or features made available on or through the Services are free of viruses or other harmful components.</p><p>13.4. Company is not liable, and You agree not to seek to hold Company liable, for the conduct of third parties on or accessed via the Services, including operators of ThirdParty Services and other users of the Services, and that the risk of injury from suchthird parties&#8217; rests entirely with You.</p>\n' +
    '<p>13.5. Company will not be liable for any delay or failure to perform resulting from causesoutside its reasonable control, including, but not limited to, acts of God, war,terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents,strikes, or shortages of transportation facilities, fuel, energy, labor, or materials.</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p><b>14. LIMITATION OF LIABILITY</b></p>\n' +
    '<p>14.1. You understand and agree that Company shall not be liable to You for any indirect,incidental, special, consequential, or exemplary damages which may be incurred byYou, regardless of the cause and under any theory of liability, including, but notlimited to, any loss of profit (whether incurred directly or indirectly), any loss ofgoodwill or business reputation, any loss of data suffered, cost of procurement ofsubstitute goods or Services, or other intangible loss, even if Company has beenadvised of the possibility of such damages.</p>\n' +
    '<p><b>15. GOVERNING LAW AND DISPUTE RESOLUTION</b></p>\n' +
    '<p>15.1. The Terms and any action related thereto will be governed and interpreted by thelaws of St. Vincent and the Grenadines, without giving effect to any principles thatprovide for the application of the law of another jurisdiction.</p>\n' +
    '<p>15.2. If any Dispute, controversy or claim of whatever nature arises under, out of or inconnection with these Terms, including any question regarding its existence, validityor termination or any non-contractual obligations arising out of or in connection withthese Terms (the &#8220;Dispute&#8221;), User and Company shall use all reasonable endeavoursto resolve the matter amicably. Where a Dispute arises, one party shall give the otherparty notice that a Dispute has arisen. Neither User nor Company shall resort tocourt until thirty (30) calendar days after the date of sending of such notice. AllDisputes, which are unresolved as agreed above, shall be settled by the competentcourt of the jurisdiction where Company is domiciled. The resolution shall be final foreither User or Company.</p>\n' +
    '<p><b>16. PERSONAL DATA</b></p>\n' +
    '<p>16.1. By accepting these Terms, You expressly consent to Company processing andexporting your personal data outside of the jurisdiction in which You reside or arelocated. Company takes all necessary and adequate organizational and technicalmeasures to protect the Users&#8217; personal data.</p>\n' +
    '<p>16.2. We will retain your information only for as long as necessary to fulfill the objectivesoutlined in this policy. We will also limit retention to what is essential for meetinglegal obligations, resolving Disputes, and enforcing agreements.</p>\n' +
    '<p><b>17. TAXES</b></p>\n' +
    '<p>17.1. You acknowledge and agree that You are solely responsible for determining andfulfilling any tax obligations associated with your use of our Services. This includes,but is not limited to, any applicable sales taxes, value-added taxes (VAT), or othersimilar taxes imposed by relevant jurisdictions.</p>\n' +
    '<p>17.2. We do not provide tax advice, and any information or materials provided by usshould not be considered as such. We recommend consulting with a qualified taxprofessional to obtain specific advice tailored to your individual circumstances.</p>\n' +
    '\n' +
    '</div></div>\n' +
    '<div style="page-break-before:always; page-break-after:always"><div><p>17.3. You agree to indemnify and hold us harmless from any claims, liabilities, penalties,fines, or other expenses arising out of your failure to comply with applicable tax lawsand regulations.</p>\n' +
    '<p>17.4. Tax laws and regulations may change over time, and it is your responsibility to stayinformed about any updates or amendments that may impact your tax obligationsrelated to our Services.</p>\n' +
    '<p>17.5. We shall not be held liable for any consequences, damages, or losses resulting fromyour failure to comply with tax laws or fulfill your tax obligations. You agree to releaseand discharge us from any such claims or liabilities.</p>\n' +
    '<p><b>18. MISCELLANEOUS</b></p>\n' +
    '<p>18.1. You consent to receive communications from Company in electronic form, includingcommunications made via the Website, Services, or sent via email.</p>\n' +
    '<p>18.2. You agree and consent to receive electronically (including email, SMS) and viewthrough the Services the advertisement, promotional, service, and informationnotifications.</p>\n' +
    '<p>18.3. Your rights and obligations hereunder may not be assigned, subcontracted,delegated, or otherwise transferred by You without Company\'s prior written consent.Company may assign its rights and obligations under these Terms to third partieswithout the consent of the User.</p>\n' +
    '<p>18.4. Company has the right to involve third parties for the fulfillment of its obligations.18.5. Nothing in these Terms shall be construed as creating a joint venture, an agency</p>\n' +
    '<p>relationship, or a legal partnership between Company and the User.18.6. If any provision of these Terms is invalidated, it does not affect the validity or</p>\n' +
    '<p>applicability of the remaining provisions of these Terms.18.7. Any waiver or failure to enforce any provision of the Terms on one occasion will not</p>\n' +
    '<p>be deemed a waiver of any other provision or of such provision on any otheroccasion.</p>\n' +
    '<p>18.8. Appeals, proposals and claims of individuals and legal entities to Company related tothe operation of the Services, violations of the rights and interests of third parties,requirements of the legislation, as well as for requests of persons authorized by thelegislation may be sent to the email address: support@coincrypto.to.</p>\n' +
    '<p>18.9. These Terms are the final, complete and exclusive agreement of the User andCompany with respect to the subject matter hereof and supersedes and merges allprior discussions between the User and Company with respect to such subjectmatter.</p>'
