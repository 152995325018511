import React from 'react';

import { Accordion } from "../../components/Accordion";
import { FAQ_TITLE_DESKTOP, listAnswer } from "../../assets/content";

import './styles.css';


export const FAQ = () => {
  return (
    <section className="faq">
      <div className="faq__wrapper">
        <h2 className="faq__title">{FAQ_TITLE_DESKTOP}</h2>
        <ul className="faq__list">
          <li className="faq__list-item">
            <Accordion title="Which currencies are available for exchange?">
              <p>200+ as of today. We have all the popular cryptocurrencies available for exchange.
              </p>
            </Accordion>
          </li>
          {listAnswer.map((item, index) =>
            <li className="faq__list-item" key={index}>
              <Accordion title={item.QUESTIONS} {...item}>
                <p>{item.ANSWER}</p>
              </Accordion>
            </li>)}
        </ul>
      </div>
    </section>
  )
};
