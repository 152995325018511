import React from 'react';

import { Widget } from "../../components/Widget";
import { jumbo, JUMBO_TITLE } from "../../assets/content";

import './styles.css';


export const Jumbotron = () => {
  return (
    <section className="jumbo row">
      <div className="jumbo__main">
        <h1 className="jumbo__main-title">{JUMBO_TITLE}</h1>
        <p className="jumbo__main-text">{jumbo.TEXT_1}</p>
        {/*<p className="jumbo__main-text last_text">{jumbo.TEXT_2}*/}
        {/*  <a rel="nofollow noopener noreferrer" target="_blank" className="jumbo__link"*/}
        {/*     href="https://web.getmonero.org/get-started/contributing/">Monero Project.</a>*/}
        {/*</p>*/}
      </div>
      <div className="jumbo__widget">
        <Widget/>
      </div>
    </section>
  )
};
